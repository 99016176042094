
import { hydrateCSRTemplate } from '@fiverr-private/layout_lib/CSRTemplate';
import { handleCSRError } from '@fiverr-private/layout_lib/CSRTemplate';
import { loader } from '/home/circleci/app/packages/layout-footer-component/src/entries/LogoMakerLP';
const getRootElement = () => typeof document !== 'undefined' && document.querySelector('#Footer');
const lazyImport = () => import(
    /* webpackChunkName: "LogoMakerLPLazyComponent" */
    /* webpackExports: ["default"] */
    '/home/circleci/app/packages/layout-footer-component/src/entries/LogoMakerLP?version=1'
);
const hydrateComponent = async () => {
    try {
        const { default: Component } = await lazyImport();
        const props = window.initialData['Footer'];
        const root = getRootElement();
        if (root) {
            hydrateCSRTemplate(Component, props, root, 'Footer', 'LogoMakerLP');
        }
    } catch(error) {
        handleCSRError(error, "Footer", "LogoMakerLP");
    }
};
const main = async () => {
    try {
        const root = getRootElement();
        if (root) {
            await loader(hydrateComponent, root);
        }
    } catch(error) {
        handleCSRError(error, "Footer", "LogoMakerLP");
    }
};
main();
export default main;
